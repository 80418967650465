exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-get-started-thank-you-tsx": () => import("./../../../src/pages/get-started/thank-you.tsx" /* webpackChunkName: "component---src-pages-get-started-thank-you-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-free-tools-overtime-wage-calculator-tsx": () => import("./../../../src/templates/free-tools/overtime-wage-calculator.tsx" /* webpackChunkName: "component---src-templates-free-tools-overtime-wage-calculator-tsx" */),
  "component---src-templates-free-tools-time-card-calculator-tsx": () => import("./../../../src/templates/free-tools/time-card-calculator.tsx" /* webpackChunkName: "component---src-templates-free-tools-time-card-calculator-tsx" */),
  "component---src-templates-get-started-tsx": () => import("./../../../src/templates/get-started.tsx" /* webpackChunkName: "component---src-templates-get-started-tsx" */),
  "component---src-templates-homepage-tsx": () => import("./../../../src/templates/homepage.tsx" /* webpackChunkName: "component---src-templates-homepage-tsx" */)
}

