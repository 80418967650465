import * as Sentry from '@sentry/gatsby';
import { isFunction } from 'lodash-es';
import './src/styles/global.css';

export const onRouteUpdate = () => {
  const faviconEl = document.querySelector('link[rel="icon"]');
  const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

  if (!mediaQuery?.addEventListener || isFunction(mediaQuery.addEventListener)) {
    return;
  }

  mediaQuery.addEventListener('change', themeChange);

  if (mediaQuery.matches) {
    faviconEl.setAttribute('href', '/favicon-dark.svg');
  } else {
    faviconEl.setAttribute('href', '/favicon.svg');
  }

  function themeChange(event) {
    console.log(event.matches ? 'dark' : 'light');
    if (event.matches) {
      faviconEl.setAttribute('href', '/favicon-dark.svg');
    } else {
      faviconEl.setAttribute('href', '/favicon.svg');
    }
  }
};

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://bc5a88c8f9424ab7a9a63d63d945a67d@o4506169737347072.ingest.sentry.io/4506276334010368',
    environment: process.env.NODE_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/api\.timewave\.io/],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
